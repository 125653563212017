export function useUploadUtils() {
  const config = useRuntimeConfig()

  const urlToCdn = (url: string) => {
    if (url && url.includes(config.public.s3Url)) {
      return url.replace(config.public.s3Url, config.public.cdnUrl)
    }

    return url
  }

  const upload = (item: any, size?: string, format?: string) => {
    let url

    if (size) {
      const upload = item?.formats?.[size] || null
      if (upload?.provider_metadata?.webp) {
        url = upload?.provider_metadata.webp
      } else {
        url = upload?.url
      }
    }

    if (!url) {
      if (item?.provider_metadata?.webp) {
        url = item?.provider_metadata?.webp || null
      } else {
        url = item?.url || null
      }
    }

    let upload = ''

    if (url) {
      if (
        !url.match(
          /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/,
        )
      ) {
        upload = urlToCdn(`https://${url}`)
      }
    }

    if (!upload) upload = urlToCdn(url)

    if (format) return upload.replace(/\.[^/.]+$/, `.${format}`)

    return upload
  }

  const sizes = (item: any, size: string) => {
    if (size) {
      const upload = item?.formats?.[size] || null
      if (upload)
        return {
          width: upload.width,
          height: upload.height,
        }
    }

    return {
      width: item?.width,
      height: item?.height,
    }
  }

  return {
    urlToCdn,
    upload,
    sizes,
  }
}
