import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.2,
    tracePropagationTargets: ['https://heyu.works'],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.2,
  })

  // eslint-disable-next-line no-console
  console.info('sentry initialized')
})
