import { marked } from 'marked'
import DOMPurify from 'isomorphic-dompurify'

export default defineNuxtPlugin(() => {
  function renderAndSanitize(input: string) {
    return DOMPurify.sanitize(marked.parse(input), {
      USE_PROFILES: { html: true },
    })
  }

  return {
    provide: {
      md: renderAndSanitize,
    },
  }
})
