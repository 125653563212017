import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import return_45to_45global from "/app/middleware/return-to.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  return_45to_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-authenticated": () => import("/app/middleware/is-authenticated.ts"),
  "is-company": () => import("/app/middleware/is-company.ts"),
  "is-professional": () => import("/app/middleware/is-professional.ts"),
  "not-authenticated": () => import("/app/middleware/not-authenticated.ts")
}