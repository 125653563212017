import type { Strapi4Error } from '@nuxtjs/strapi/dist/runtime/types/v4'

export default defineNuxtPlugin((nuxt) => {
  const config = useRuntimeConfig()
  nuxt.hook('strapi:error' as any, (e: Strapi4Error) => {
    if (
      config.public.debugging.logStrapi === 'true' ||
      config.public.debugging.logStrapi === true
    ) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  })
})
