import { defineNuxtPlugin } from '#app/nuxt'
import { LazySlideOverOnboarding, LazySlideOverCockpitAppointmentProposal, LazySlideOverCockpitInteractionDeny, LazySlideOverCockpitPlacement, LazySlideOverCompaniesEmployee, LazySlideOverCompaniesMedia, LazySlideOverCompaniesPersonalia, LazySlideOverCompaniesTags, LazySlideOverCompaniesWhyWorkWithUs, LazySlideOverCompaniesAddToTalentPool, LazySlideOverCompaniesShowInterest, LazySlideOverProfessionalExperience, LazySlideOverProfessionalPersonalia, LazySlideOverProfessionalPlace, LazySlideOverProfessionalTags, LazySlideOverSelectCultures, LazySlideOverSelectMotivation, LazySlideOverSelectRoles, LazySlideOverSelectTags, LazySlideOverTeamAssessmentsCandidate, LazySlideOverTeamAssessmentsCandidateCreate, LazyModalAssessmentMotivations, LazyModalAssessmentPersonality, LazyModalCockpitProfessionalsInvite, LazyModalConfirmation, LazyModalCustomCookies, LazyModalTalentpoolCreate, LazyModalTalentpoolNoneCreated, LazyModalTalentpoolTransfer } from '#components'
const lazyGlobalComponents = [
  ["SlideOverOnboarding", LazySlideOverOnboarding],
["SlideOverCockpitAppointmentProposal", LazySlideOverCockpitAppointmentProposal],
["SlideOverCockpitInteractionDeny", LazySlideOverCockpitInteractionDeny],
["SlideOverCockpitPlacement", LazySlideOverCockpitPlacement],
["SlideOverCompaniesEmployee", LazySlideOverCompaniesEmployee],
["SlideOverCompaniesMedia", LazySlideOverCompaniesMedia],
["SlideOverCompaniesPersonalia", LazySlideOverCompaniesPersonalia],
["SlideOverCompaniesTags", LazySlideOverCompaniesTags],
["SlideOverCompaniesWhyWorkWithUs", LazySlideOverCompaniesWhyWorkWithUs],
["SlideOverCompaniesAddToTalentPool", LazySlideOverCompaniesAddToTalentPool],
["SlideOverCompaniesShowInterest", LazySlideOverCompaniesShowInterest],
["SlideOverProfessionalExperience", LazySlideOverProfessionalExperience],
["SlideOverProfessionalPersonalia", LazySlideOverProfessionalPersonalia],
["SlideOverProfessionalPlace", LazySlideOverProfessionalPlace],
["SlideOverProfessionalTags", LazySlideOverProfessionalTags],
["SlideOverSelectCultures", LazySlideOverSelectCultures],
["SlideOverSelectMotivation", LazySlideOverSelectMotivation],
["SlideOverSelectRoles", LazySlideOverSelectRoles],
["SlideOverSelectTags", LazySlideOverSelectTags],
["SlideOverTeamAssessmentsCandidate", LazySlideOverTeamAssessmentsCandidate],
["SlideOverTeamAssessmentsCandidateCreate", LazySlideOverTeamAssessmentsCandidateCreate],
["ModalAssessmentMotivations", LazyModalAssessmentMotivations],
["ModalAssessmentPersonality", LazyModalAssessmentPersonality],
["ModalCockpitProfessionalsInvite", LazyModalCockpitProfessionalsInvite],
["ModalConfirmation", LazyModalConfirmation],
["ModalCustomCookies", LazyModalCustomCookies],
["ModalTalentpoolCreate", LazyModalTalentpoolCreate],
["ModalTalentpoolNoneCreated", LazyModalTalentpoolNoneCreated],
["ModalTalentpoolTransfer", LazyModalTalentpoolTransfer],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
